import React, {useEffect, useMemo} from 'react'
import {ButtonLink, Container} from 'grilnica-reactstrap'
import {bindPaymentActions} from '../../../../../store/ducks/payment'
import {connectComponent} from '../../../../../store/common'
import {getStaticUrl} from '../../../../../utils/static'

interface PaymentSuccessPageProps {
  orderId: string
  paymentActions: any
}

const PaymentSuccessPage: React.FC<PaymentSuccessPageProps> = ({
  orderId,
  paymentActions,
}): React.ReactElement => {
  useEffect(() => {
    if (orderId) {
      paymentActions.setIsSuccessPaymentRequest(orderId)
    }
  }, [paymentActions, orderId])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <Container size={'normal'} className={'mt-x10 mb-x10'}>
        <div className={'payment'}>
          <h1 className={'h1 mb-x6'}>Оплата успешна</h1>
          <img
            src={getStaticUrl('/img/payment-success.svg')}
            alt={'Оплата успешна'}
            title={'Оплата успешна'}
            width={'100%'}
            height={'auto'}
            className={'mb-x6'}
          />
          <p className={'descriptions'}>Статус заказа можно отслеживать в личном кабинете</p>
          <ButtonLink
            to={'/profile'}
            color={'primary'}
            className={'btn-size mb-x6'}
            isRoundedButton>
            Личный кабинет
          </ButtonLink>
          <ButtonLink
            to={'/'}
            color={'primary'}
            className={'btn-size'}
            isRoundedButton
            style={'outline'}>
            Меню
          </ButtonLink>
        </div>
      </Container>
    )
  }, [])
  return <>{renderContent}</>
}
export default connectComponent(
  (state: any, props: any) => ({
    orderId: new URLSearchParams(props.location.search).get('orderId'),
  }),
  (dispatch: any) => ({
    paymentActions: bindPaymentActions(dispatch),
  }),
  PaymentSuccessPage,
)
